.item-plan {
  display: flex;
  align-items: center;
}

.item-done-color {
  color: #78ff78;
}

.item-done-text {
  text-decoration: line-through;
}